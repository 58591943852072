import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment';

@Component({
  selector: 'sa-footer',
  templateUrl: './footer.component.html',
  styleUrls:['footer.component.scss']
})
export class FooterComponent implements OnInit {
  accountsUrl = environment.ACCOUNTS_URL[localStorage.getEncryptedItem('domain')];
  year = new Date().getFullYear();
  companyName: any;
  constructor() {}

  ngOnInit() {
    let domain = localStorage.getEncryptedItem('domain');
    this.companyName = environment.COMPANY_NAME[domain].replace(/ AG$/, '');
  }

}
