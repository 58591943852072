import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
  HttpResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthTokenService } from './auth-token.service';

import { Store } from '@ngrx/store';

import * as fromAuth from '../store/auth';
import { catchError,tap } from 'rxjs/operators';
import { environment } from '@env/environment';
import * as crypto from 'crypto-browserify';
import { CommonService } from '@app/core/services/common.service';
import { EmitService } from '@app/ts/services/emit.service';
declare var Buffer: any;

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  encryptionKey: string;
  excludeTokenVerification =[]
  constructor(
    private injector: Injector,
    public authToken: AuthTokenService,
    private commonService: CommonService,
    private emitService: EmitService,
    public store: Store<fromAuth.AuthState>
  ) { 
    this.encryptionKey = environment.encryptionKey;
    this.excludeTokenVerification= [`${environment.API_END_POINT_ACCOUNT}auth/authlogin`]
  }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
   
      request = request.clone({
        withCredentials: true
      });
      request = this.addHeader(request);

    if (request.url.search(environment.API_END_POINT_ACCOUNT[localStorage.getEncryptedItem('domain')]) === 0) {
      // attach token
      let cookieExist = this.commonService.decrypt(decodeURIComponent(this.commonService.getCookie(environment.checkCookie)));
      if(cookieExist) {
        cookieExist = JSON.parse(cookieExist);      
        if(localStorage.getEncryptedItem('partyID')&&localStorage.getEncryptedItem('partyID')!=cookieExist['partyID']) {
          localStorage.removeEncryptedItem('userData');
          localStorage.setEncryptedItem('partyID',cookieExist['partyID'])
          location.reload();
        }
      }
      return this.handleApiRequest(request, next);
    } 
    // else {
    //   // return next.handle(request);
    //   return this.handleApi(request,next);
    // }
    if (request.url.search(environment.API_END_POINT_ADMIN[localStorage.getEncryptedItem('domain')]) === 0) {
      return this.handleApiRequest(request, next);
    } else {
      return this.handleApi(request,next);
    }
  }

  private addHeader(req: HttpRequest<any>) {
    console.log('url',req.url);
    
    const logParams = {}; 
    if (req.body instanceof FormData) {
      if (!req.body.has('partyIdLog')) {
        req.body.append('partyIdLog', localStorage.getEncryptedItem('partyID'));
      }
      if (!req.body.has('accIdLog')) {
        req.body.append('accIdLog', localStorage.getEncryptedItem('accId'));
      }
      req =  req.clone({
        body: req.body
      })
    } else {
      // const formData = {}; 
      // // formData['partyId'] = localStorage.getEncryptedItem('partyID');
      // // formData['accId'] = localStorage.getEncryptedItem('accId');
      // req =  req.clone({
      //   body: {...req.body, ...formData}
      // })
    } 
    return req.clone({
      headers: new HttpHeaders(),
      withCredentials: true
    });
  }

  handleApiRequest(request, next) {    
    // let request1 = request.clone({ headers: request.headers.set('withCredentals', 'true') })
    const handler = next.handle(request).pipe(
      tap(res=> {
        if (res instanceof HttpResponse) {
               if(res.body.data=='cookie_not_found'){
                this.emitService.subscribeapiError(true);  
               }
                                               
        }
    }),
      catchError((error, caught) => {
        if ((error.status === 401 || error.status === 403 ) && request.url != environment.API_END_POINT_ACCOUNT+'auth/authlogin') {
          let currentURL ='https://admin.'+localStorage.getEncryptedItem('domain')+'/#/companyDetails';
          window.location.href = environment.LOGOUT_URL[localStorage.getEncryptedItem('domain')]+encodeURIComponent(this.commonService.encrypt(currentURL))
          return throwError(error);
        } else {
          return throwError(error);
        }
      })
    );

    return handler;
  }

  handleApi(request,next) {
    const handler = next.handle(request).pipe(
      tap(res=> {
          if (res instanceof HttpResponse) {  
                 if(res.body.data=='cookie_not_found'){
                  this.emitService.subscribeapiError(true); 
                 }
                                                  
          }
      }));
    return handler;
  }  
}
