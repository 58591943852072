import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from './app.service';
import { CommonService } from '@app/core/services/common.service';
import { UserService, LayoutService } from './core/services';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { I18nService } from './shared/i18n/i18n.service';
import { EmitService } from './ts/services/emit.service';
import { environment } from '@env/environment';
import { VersionCheckService } from "@app/shared/versioncheck/version-check.service";
declare var $:any;
@Component({
  selector: 'app-root',
  template: `<router-outlet></router-outlet> <div class="cookie-consent hide-consent show-consent" *ngIf="showCookieConsent"><span class="cookie-text">{{'common_login_cookie_content' |i18n}} <a class="cookie_anchor" [href]="accountsUrl+'privacy-policy'" target="_blank">{{'common_all_privacy_policy' | i18n}} </a></span><button class="consent-btn mt-4" (click)="hideCookieConsent()"> {{'footer_cookie_gotIt' | i18n}}</button></div>`,
})
export class AppComponent implements OnInit{
  countryList:any = [];
  idleState = "NOT_STARTED";
  countdown?: number = null;
  lastPing?: Date = null;
  action = '';
  showCookieConsent:boolean;
  constructor(private appService : AppService,private router: Router,private commonService:CommonService,private userService: UserService,private idle: Idle,private layoutService:LayoutService,private emitService:EmitService,private versionCheckService:VersionCheckService){
    this.appService.setLocalStorageFunctions();
    // this.getCountryList();
    // this.setPermision();
    setInterval(() => {
      this.appService.createAccessToken().subscribe(data=>{ }); 
    },600000);
    let cookieExist = this.commonService.decrypt(decodeURIComponent(this.commonService.getCookie(environment.checkCookie)));
    // let cookieExist = this.commonService.decrypt(decodeURIComponent('r629QRYi0NfhX8n25sJEipLxfd8WqEBqzPeIiC38Ghk7TpPQTGYtQNmXQ%2FmyapOsDeA3OzirbI%2BAyCHhQYA7VqKrVEPDsDwZ8awZWUaM%2FrjmIflMtTz9wC%2FNJ%2BTXP9%2Bo1LPwR2sIumSOzBqd3257l2ny0T%2Bac1BamDXPt5EKSH2h%2F%2FofrJHoKS9jP61AzqDo%2FjSPvT5ks2diXTonh7UDWIGiEthg6UQ8ZTP184kMNvJ2cqqvFXH7EyoHpaOcAQZK'));
    if (cookieExist) {
      // this.setPermision();
      cookieExist =  JSON.parse(cookieExist);
      localStorage.getEncryptedItem('partyID') != cookieExist['partyID'] || localStorage.getEncryptedItem('userID') != cookieExist['userID'] ? localStorage.removeEncryptedItem('userData') : '';
      localStorage.setEncryptedItem('userMail',cookieExist['userMail']);
      localStorage.setEncryptedItem('partyID',cookieExist['partyID']);
      localStorage.setEncryptedItem('userID',cookieExist['userID']);
      localStorage.setEncryptedItem('accId',cookieExist['accId']);
      localStorage.setEncryptedItem('netcomId',cookieExist['netcomId']);
      localStorage.setEncryptedItem('isBexioCompany',cookieExist.hasOwnProperty('isBexioCompany')?cookieExist['isBexioCompany']:'false');
    }
    const host = window.location.host
    const parts = host.split(".");
    parts.shift(); 
    const result = parts.join(".");
    localStorage.setEncryptedItem('domain',result);
    // let p = String(["admin.companyProfile.*","admin.user.*","admin.billing.*","admin.subscription.*","accounts.profile.*"]);
    // let a = this.commonService.encrypt(p);
    // localStorage.setEncryptedItem('permissions',a); 
    // set idle parameters
    this.idle.setIdle(1800); // how long can they be inactive before considered idle, in seconds
    this.idle.setTimeout(10); // how long can they be idle before considered timed out, in seconds
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // provide sources that will "interrupt" aka provide events indicating the user is active

    // do something when the user becomes idle
    this.idle.onIdleStart.subscribe(() => {
      this.idleState = "IDLE";
    });
    // do something when the user is no longer idle
    this.idle.onIdleEnd.subscribe(() => {
      this.idleState = "NOT_IDLE";
      this.countdown = null;
      // cd.detectChanges(); // how do i avoid this kludge?
    });
    // do something when the user has timed out
    this.idle.onTimeout.subscribe(() => {
      this.idleState = "TIMED_OUT";
      let currentURL ='https://admin.'+localStorage.getEncryptedItem('domain')+'/#/companyDetails';
      

      let lastActive;
      lastActive = Date.parse(this.commonService.decrypt(this.commonService.getCookie(this.commonService.encrypt(environment.USER_ACTIVE_CHECK))))
      let timeDiff;
      timeDiff = Date.now() - lastActive;
      let minuteDiff = timeDiff / (1000 * 60)
      if(minuteDiff > 30)
      {
        let domain = localStorage.getEncryptedItem('domain')
        localStorage.clear();
        window.location.href = environment.LOGOUT_URL[domain]+encodeURIComponent(this.commonService.encrypt(currentURL))
      }else{
        this.reset();
      }
   
    });
    // do something as the timeout countdown does its thing
    this.idle.onTimeoutWarning.subscribe(seconds => this.countdown = seconds);
  }
  async ngOnInit() {
    $("body").addClass(localStorage.getEncryptedItem('domain').split(".")[0]);
    console.log(environment.STYLES[localStorage.getEncryptedItem('domain')]);
    environment.STYLES[localStorage.getEncryptedItem('domain')].forEach(element => {
      document.documentElement.style.setProperty(Object.keys(element)[0], element[Object.keys(element)[0]]);
      // console.log('element',element);
      
    });
  //  await this.setPermision();
  this.cookieConsent();
    this.reset();
    /* setTimeout(() => {
      let language = localStorage.getEncryptedItem('userData') ? JSON.parse(localStorage.getEncryptedItem('userData'))[0].language: "de";
      this.emitService.emitLoginLanguageChange(language);
    },100); */
  // this.i18nService.setLanguage(v)
  // console.log(lang);
  // let lang =  this.commonService.getCookie(this.commonService.encrypt('languageCookie'));
  // console.log(lang);
  // console.log(this.commonService.decrypt(lang));
  // let defaultLang = this.commonService.decrypt(lang)
  // console.log('lang',defaultLang);
  this.versionCheckService.initVersionCheck('version.json');

  }

  reset() {
    // we'll call this method when we want to start/reset the idle process
    // reset any component state and be sure to call idle.watch()
    this.idle.watch();
    this.idleState = "NOT_IDLE";
    this.countdown = null;
    this.lastPing = null;
  }
  cookieConsent(){
      if( this.commonService.getCookie(environment.cookieConsent) == 'false' ){
        this.showCookieConsent = false;
      
    }else{
      this.showCookieConsent = true;
      this.commonService.setCookie(environment.cookieConsent,"true",10000,environment.cookieDomain,'/');
    }
  }
  hideCookieConsent(delay =0){
    if(this.showCookieConsent){
      setTimeout(()=> { 
        $(".cookie-consent").fadeOut(1500);  
        this.showCookieConsent = false ; 
        this.commonService.setCookie(environment.cookieConsent,"false",10000,environment.cookieDomain,'/');     
      }, delay)
    }
  }
}
