import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  apiUrl:string;
  adminApiUrl:string;

  constructor(private httpClient: HttpClient) {
    this.apiUrl = environment.API_END_POINT_ACCOUNT[localStorage.getEncryptedItem('domain')];
    this.adminApiUrl = environment.API_END_POINT_ADMIN[localStorage.getEncryptedItem('domain')]
   }
   getUserImg(accID){
    return this.httpClient.post(this.apiUrl+`users/fetch-image`,accID)
  }

  checkBillingDetails(accID){
    return this.httpClient.post(this.adminApiUrl+`admin/checkBillingDetails`,accID)
  }
  totalColumns(companyId ){
    return this.httpClient.post(this.adminApiUrl+`admin/totalColumns`,companyId )

  }
  getCompanyDetails(encrData){
    return this.httpClient.post(this.adminApiUrl +`admin/company-details`,encrData).pipe(map((response: Response) => response));
  }
  getCompanyImg(data){
    return this.httpClient.post(this.adminApiUrl + 'admin/getCompanyLogo', data).pipe(map((response: Response) => response)); 
  }
}
